<template>
  <div
    id="cgu_hotline_de"
    class="text-regular"
  >
    <div class="bloc">
      <!-- TODO: Non disponible pour NBP pour l'instant -->
      {{ $t("legal.non-disponibles-sur cet-environnement") }}
    </div>
  </div>
</template>

<script>
/**
 * Affiche la page des CGU de Hotline en allemand.
 */
export default {
  name: "CguHotlineDe",
};
</script>

<style lang="scss"></style>
