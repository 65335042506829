<template>
  <div id="cgu_hotline">
    <CguHotlineDe v-if="country === 'de'" />
    <CguHotlineFr v-else />
  </div>
</template>

<script>
import CguHotlineFr from "@/components/legal/hotline/CguHotlineFr.vue";
import CguHotlineDe from "@/components/legal/hotline/CguHotlineDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les CGU de Hotline dans la bonne langue.
 */
export default {
  name: "CguHotline",
  components: {
    CguHotlineFr,
    CguHotlineDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
